<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Hits Reporting</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.status" class="form-control">
                                <option value="" selected>-- Select Status --</option>
                                <option selected v-for="stats in statuses" :key="stats._id" :value="stats">{{ stats }}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.client_id" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client.value" :value="client.value">{{ client.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.campaign_id" class="form-control">
                                <option value="" selected>-- Any Campaign --</option>
                                <option v-for="campaign in dropdownCampaigns" :key="campaign.value" :value="campaign.value">{{ campaign.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.product_id" class="form-control">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in dropdownProducts" :key="product.value" :value="product.value">{{ product.text }}</option>
                            </select>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i
                                class="fa fa-search"
                                title="Search"
                            >
                            </i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="report"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column headerText='Campaign ID' field="proxy" :template="template"></e-column>
                            <!--                        <e-column headerText=''-->
                            <e-column field='name' headerText='Campaign Name'></e-column>
                            <e-column field='client' headerText='Client'></e-column>
                            <e-column field='total_hits' headerText='Total Hits'></e-column>
                            <!--                        <e-column field='successful_hits' headerText='Successful Hits'></e-column>-->
                            <!--                        <e-column field='failed_hits' headerText='Failed Hits'></e-column>-->
                            <!--                        <e-column headerText='Details' :template="cTemplate"></e-column>-->
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
// import VSelect from 'vue-select'

import Vue from "vue";
import {GridPlugin, Page, Sort, ExcelExport } from "@syncfusion/ej2-vue-grids";
import cTemplate from "@/components/cTemplate";
import swal from "sweetalert";

Vue.use(GridPlugin);

export default {
    name: 'CampaignReport',
    props: ['user'],
    components: {},
    data: function () {
        return {
            action: '',
            campaign: null,
            filter: {
                to: '',
                from: '',
                campaign_id: "",
                client_id: "",
                product_id: '',
                status:''
            },
            dropdownClients: [],
            dropdownCampaigns: [],
            dropdownProducts: [],
            report: [],
            providers: [],
            states: [],
            cities: [],
            clients: [],
            statuses:["Successful","Failed"],
            hitTitle:"Total Hits",
            campaignDataset: {},
            pageSettings: {pageSize: 100},
            searching: false,
            showExportButton: false,
            reportLoading: false,
            template: function () {
                return {
                    template : cTemplate
                }
            }
        }
    },
    provide: {
        grid: [Page, Sort, ExcelExport ]
    },
    created: function () {
        this.load();
        window.addEventListener("keydown", this.windowListener);
    },
    destroyed: function () {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.searching) {
                this.search();
            }
        },
        onSearch(search, loading) {
            loading(true);
            this.campaignSearch(loading, search, this);
        },
        campaignSearch: function (loading, search, vm) {
            if(search.length > 0){
                //Load the campaign
                let request = {'apikey': vm.$root.apikey, filters: {}};
                request.query = search;

                axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`, {params: request}).then(function (resp) {
                    vm.campaigns = (resp.data && resp.data.data) ? resp.data.data : null;
                    loading(false);
                });
            }else{
                loading(false);
            }
        },
        search: function () {
            if(new Date(this.filter.from) < this.getDateDifference()){
                swal({title:'Oops', text: `Out of range, please select dates within 2 months.`, icon: 'error'})
                return;
            }

            this.searching = true;
            this.showExportButton = false;
            this.reportLoading = true;
            this.report = [];
            this.campaignDataset = {};

            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    from: new Date(),
                    to: new Date()
                }
            };

            request.filters.from = this.filter.from;
            request.filters.to = this.filter.to;
            request.filters.product_id = this.filter.product_id;

            if (this.filter.client_id) {
                request.filters.client = this.filter.client_id;
                request.filters.campaign = "";
            }
            if (this.filter.campaign_id) {
                request.filters.campaign = this.filter.campaign_id;
                request.filters.client = "";
            }
            if(this.filter.status){
                request.filters.status = this.filter.status;
                if(this.filter.status === "Failed"){
                    this.hitTitle = "Total Failed Hits";
                }else if(this.filter.status === "Successful"){
                    this.hitTitle = "Total Successful Hits";
                }
                else{
                    this.hitTitle = "Total Hits";
                }
            }
            if(this.filter.status) {
                axios.get(`${this.$root.serverUrl}/admin/reports/hits`, {params: request}).then(function (resp) {
                    //Store the stats
                    if (resp.data.error) {
                        this.reportLoading = false;
                        swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                    } else {
                        const result = resp.data.data;
                        if (result.length > 0) {
                            const tasks = [];
                            for (let i in result) {
                                tasks.push(this.stage(result[i]));
                                console.log(result[i])
                            }
                            Promise.all(tasks).then(function () {
                                for (const item in this.campaignDataset) {
                                    this.report.push(this.campaignDataset[item]);
                                }
                                this.showExportButton = true;
                                this.reportLoading = false;
                            }.bind(this));
                        } else {
                            this.reportLoading = false;
                        }
                    }
                    this.searching = false;
                }.bind(this)).catch((err) => {
                    this.reportLoading = false;
                    swal({
                        title: 'Oops',
                        text: `Sorry, I am unable to complete your request. ${err.message}`,
                        icon: 'error'
                    })
                });
            }else{
                this.searching = false;
                this.reportLoading = false;
                swal({
                    title: 'Oops',
                    text: `Please select a status from the dropdown`,
                    icon: 'error'
                })
            }
        },
        load: function () {
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.GetClientsList();
            this.GetProductList();
        },
        GetClientsList: function(){
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        GetCampaignsList: function() {
            this.filter.campaign_id = '';
            let request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client/campaign/${this.filter.client_id}`, {params:request}).then(function(resp){
                this.dropdownCampaigns = ( resp.data ) ? resp.data.data : [];
            }.bind(this));
        },
        GetProductList: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/product/crawler`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownProducts = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        stage: function (dataset) {
            console.log(dataset)
            return new Promise(function (resolve) {
                if (!this.campaignDataset.hasOwnProperty(dataset.campaign_id)) {
                    this.campaignDataset[dataset.log_log_campaign_id] = {
                        id: dataset._id.campaign,
                        name: dataset.campaign_name,
                        type: dataset.log_campaign_type,
                        client: dataset.log_client_name,
                        clientId: dataset.log_client_id,
                        total_hits: dataset.total,
                        successful_hits: (dataset.log_type? dataset.log_type.toLowerCase() : null  === "track") ? 1 : 0,
                        failed_hits: (dataset.log_type ? dataset.log_type.toLowerCase() : null !== "track") ? 1 : 0,
                        details: []
                    };

                } else {
                    this.campaignDataset[dataset.campaign_id].total_hits++;
                    if (dataset.log_type.toLowerCase() === "track") {
                        this.campaignDataset[dataset.log_log_campaign_id].successful_hits++;
                    } else {
                        this.campaignDataset[dataset.log_log_campaign_id].failed_hits++;
                    }
                }
                this.campaignDataset[dataset.log_log_campaign_id].details.push({
                    log_id: dataset.log_id,
                    log_domain: dataset.log_domain,
                    log_node: dataset.log_node,
                    log_date: this.getDate(dataset.date),
                    log_time: this.getTime(dataset.date),
                    log_type: dataset.log_type,
                    log_message: dataset.log_message,
                    log_tracker: dataset.log_tracker,
                    log_proxy_id: dataset.log_proxy_id,
                    log_proxy_ip: dataset.log_proxy_ip,
                    log_proxy_port: dataset.log_proxy_port,
                    log_proxy_provider:dataset.proxy_provider,
                    log_proxy_protocol: dataset.log_proxy_protocol,
                    log_proxy_country: dataset.log_proxy_country,
                    log_proxy_state: dataset.log_proxy_state,
                    log_proxy_city: dataset.log_proxy_city,
                    log_proxy_weight: dataset.log_proxy_weight,
                    log_proxy_latitude: dataset.log_proxy_latitude,
                    log_proxy_longitude: dataset.log_proxy_longitude,
                    log_proxy_timezone: dataset.log_proxy_timezone,
                    log_log_campaign_id: dataset.log_log_campaign_id,
                    log_campaign_name: dataset.campaign_name,
                    log_campaign_type: dataset.log_campaign_type,
                    log_client_id: dataset.log_client_id,
                    log_client_name: dataset.log_client_name
                });
                return resolve();
            }.bind(this));
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign_id} ${this.filter.client_id}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        getDateDifference: function(){
            const toDate = new Date(this.filter.to);
            let fromDate = new Date(this.filter.to);
            //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
            fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
            return fromDate;
        },
        fixDates: function(){
            this.filter.from = this.getDateFormatted(this.getDateDifference());
        }
    },
    watch:{
        "filter.client_id": function(){
            if(this.filter.client_id.length > 0){
                this.GetCampaignsList();
            }else{
                this.dropdownCampaigns = [];
                this.filter.campaign_id = "";
            }
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
.detailtable td {
    font-size: 13px;
    padding: 4px;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chart {
    width: 100%;
    height: 300px;
}

.d-center {
    display: flex;
    align-items: center;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
    color: #fff;
}
</style>
