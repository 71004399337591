<template>
    <div>
        <!--        <button @click="viewDetails" class="btn btn-sm btn-info"><i class="fa fa-line-chart"></i></button>-->
        <a @click="viewDetails" style="cursor: pointer; color: blue">{{ data.id }}</a>
        <b-modal
            v-model="modalShow"
            scrollable
            cancel-disabled
            :title="modalTitle"
        >
            <div class="row">
                <div class="col-12" >
                    <div class="mr-1 p-2" v-for="item in data.details" :key="item.log_id">
                        <b-card
                            border-variant="dark"
                            :header="`${item.log_date} at ${item.log_time}`"
                            align="center"
                            :sub-title="`ID: ${item.log_id}`"
                        >
                            <b-list-group>
                                <b-list-group-item><strong>Type: </strong> {{item.log_type}}</b-list-group-item>
                                <b-list-group-item> <strong>Message: </strong> {{ item.log_message }}</b-list-group-item>
                                <b-list-group-item><strong>Domain: </strong> {{ item.log_domain }}</b-list-group-item>
                                <b-list-group-item> <strong>Proxy Country: </strong> {{ item.log_proxy_country }}</b-list-group-item>
                                <b-list-group-item><strong>Proxy State: </strong> {{ item.log_proxy_state }} </b-list-group-item>
                                <b-list-group-item><strong>Proxy City:</strong> {{ item.log_proxy_city }} </b-list-group-item>
                                <b-list-group-item><strong>Proxy Protocol:</strong> {{ item.log_proxy_protocol }} </b-list-group-item>
                                <b-list-group-item><strong>Proxy IP:</strong> {{ item.log_proxy_ip }} </b-list-group-item>
                                <b-list-group-item><strong>Proxy Port:</strong> {{ item.log_proxy_port }} </b-list-group-item>
                                <b-list-group-item><strong>Proxy Weight:</strong> {{ item.log_proxy_weight }} </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="modalShow = false">
                    OK
                </b-button>
            </template>
        </b-modal>
    </div>

</template>

<script>

import { BModal } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'

export default {
    name: "columnTemplate",
    components: {'b-modal': BModal, 'b-list-group': BListGroup },
    data: function() {
        return {
            data: {},
            modalTitle:"Modal Title",
            fields:[
                { label: "Log ID", key: "log_id" },
                { label: "Date", key: "log_date" },
                { label: "Time", key: "log_time" },
                { label:"Type", key:"log_type" },
                { label: "Error Message", key:"log_message" },
                { label:"Domain", key:"log_domain" },
                { label: "Proxy Protocol", key: "log_proxy_protocol" },
                { label: "Proxy Host/IP", key: "log_proxy_ip" },
                { label: "Proxy Port", key: "log_proxy_port" },
                { label: "Proxy Country", key: "log_proxy_country" },
                { label: "Proxy State", key: "log_proxy_state" },
                { label: "Proxy City", key: "log_proxy_city" },
                { label: "Proxy Weight", key: "log_proxy_weight" }
            ],
            modalShow:false,
        }
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
